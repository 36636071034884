import i18n from "../../resources/I18n";
import "./clickToCall.css";
import { obtenirURLClickToCall } from "../../util/ClickToCall";
import { CybermetriesJAMPP } from "../../util/CybermetrieJAMPP";
import { useCybermetrieJAMPPContext } from "../utils/CybermetrieJAMPPContext";

interface IClickToCall {
  noDemande?: string;
  transit?: string;
  id?: string;
}

export default function ClickToCall(props: Readonly<IClickToCall>) {
  let cybermetrieParams = useCybermetrieJAMPPContext();

  return (
    <div id={props.id} data-testid="container-click-to-call">
      <h2 id="titre-click-to-call">{i18n.t("jampp:click-to-call-titre")}</h2> <hr data-testid="ligne-noire" />
      <div className="container-ctc">
        <div className="container-demandez-appel">
          <b data-testid="demandez-appel">{i18n.t("jampp:demandez-appel")}</b>
          <div className="texte-demandez-appel">
            <p data-testid="click-to-call-texte">{i18n.t("assurabilite:click-to-call-texte")}</p>
          </div>
          <dsd-link-action
            onClick={() => CybermetriesJAMPP.EnvoiePlanifierAppel(cybermetrieParams)}
            data-testid="dsd-link-action"
            type="external"
          >
            <a
              data-testid="clic-appeler-conseiller"
              href={obtenirURLClickToCall(
                props.noDemande,
                props.transit,
                "Demande pret perso - Questionnaire",
                "FIN_PretPerso"
              )}
              target="_blank"
              rel="noreferrer"
            >
              {i18n.t("assurabilite:click-to-call-texte-lien")}
              <span className="dsd-sr-only">&nbsp;{i18n.t("sr-only-ouverture-nouvel-onglet")}</span>
            </a>
          </dsd-link-action>
        </div>
      </div>
    </div>
  );
}
