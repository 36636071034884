//@ts-check
// eslint-disable-next-line no-unused-vars
class Configuration {
  /**
   * @typedef Configurations
   * @type {Object}
   * @property {String} ENV - Environnement courant
   * @property {Boolean} authenfication - authentification Auth0
   * @property {String} urlAssurabiliteBFF - urlAssurabiliteBFF
   * @property {String} urlClickToCallDesktopFR - urlClickToCallDesktopFR
   * @property {String} urlClickToCallDesktopEN - urlClickToCallDesktopEN
   * @property {String} urlClickToCallMobileFR - urlClickToCallDesktopFR
   * @property {String} urlClickToCallMobileEN - urlClickToCallDesktopEN
   * @property {String} urlSommaire - urlSommaire
   * @property {Array<Object>} consommateurCallBackInfos - consommateurCallBackInfos
   *
   */

  constructor() {
    this.env =
      // @ts-ignore
      typeof window !== "undefined" &&
      // @ts-ignore
      typeof window["envvars"] !== "undefined" &&
      // @ts-ignore
      typeof window["envvars"].REACT_APP_ENV !== "undefined"
        ? // @ts-ignore
          String(window["envvars"].REACT_APP_ENV)
        : "prod";

    this.configurationsForAllEnvironments = this.obtenirConfigurationsForAllEnvironments();
  }

  /**
   * Pour obtenir les configurations de l'environnement courant
   * @returns {Configurations}
   */
  obtenirConfigurations() {
    /** @type {Configurations} **/
    return this.configurationsForAllEnvironments[this.env];
  }

  /**
   * Pour obtenir les configurations de l'environnement courant
   * @returns {Object.<string, Configurations>}
   */
  obtenirConfigurationsForAllEnvironments() {
    return {
      /** @type {Configurations} **/
      local: {
        ENV: "local",
        authenfication: false,
        // @ts-ignore
        urlAssurabiliteBFF:
          "https://wiremock-net-dev-ass-part-select-risque.apps.cfzcea.dev.desjardins.com/Assurabilite-BFF/v1",
        //urlAssurabiliteBFF: "https://assurabilite-bff-dev-ass-part-select-risque.apps.cfzcea.dev.desjardins.com",
        urlClickToCallDesktopFR:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/dcom-pleine-page",
        urlClickToCallDesktopEN:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/dcom-full-page",
        urlClickToCallMobileEN:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/mobile-services",
        urlClickToCallMobileFR:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/services-mobiles",
        urlSommaire: "https://accweb-martine.mouvdev.desjardins.com:443/identifiantunique/sso/redirect",
        consommateurCallBackInfos: [
          {
            idCallback: "d1c8c1fc-6991-4f2c-b791-30d3cc459eb6",
            description: "RHN - DEV",
            url: "https://front-web-dev-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "33d2a6c7-f4de-4dc8-8cea-199c51abce68",
            description: "RHN - QA",
            url: "https://front-web-qa-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "6c472c2a-e8e0-4dee-9406-4677415ee9c8",
            description: "RHN - HA",
            url: "https://front-web-ha-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "f2d71a50-747a-4556-b232-3e8a9bc305ae",
            description: "Assurance credit",
            url: "https://assurancecreditepargne.assurancevie.dev.desjardins.com/",
          },
          {
            idCallback: "2685f90d-3da5-4ba3-8180-65db0533e307",
            description: "SPA Souscription - DEV",
            url: "https://assurancecreditepargne-adhesion.dev.desjardins.com/",
          },
          {
            idCallback: "0a16edf6-6d9a-4c72-a6e2-2b15b20391ab",
            description: "SPA Souscription - DEV - EN",
            url: "https:/creditsavingsinsurance-adhesion.dev.desjardins.com/",
          },
        ],
      },
      dev: {
        ENV: "dev",
        authenfication: false,
        // @ts-ignore
        urlAssurabiliteBFF:
          "https://wiremock-net-dev-ass-part-select-risque.apps.cfzcea.dev.desjardins.com/Assurabilite-BFF/v1",
        urlClickToCallDesktopFR:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/dcom-pleine-page",
        urlClickToCallDesktopEN:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/dcom-full-page",
        urlClickToCallMobileEN:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/mobile-services",
        urlClickToCallMobileFR:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/services-mobiles",
        urlSommaire: "https://accweb-martine.mouvdev.desjardins.com:443/identifiantunique/sso/redirect",
        consommateurCallBackInfos: [
          {
            idCallback: "d1c8c1fc-6991-4f2c-b791-30d3cc459eb6",
            description: "RHN - DEV",
            url: "https://front-web-dev-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "33d2a6c7-f4de-4dc8-8cea-199c51abce68",
            description: "RHN - QA",
            url: "https://front-web-qa-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "6c472c2a-e8e0-4dee-9406-4677415ee9c8",
            description: "RHN - HA",
            url: "https://front-web-ha-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "f2d71a50-747a-4556-b232-3e8a9bc305ae",
            description: "Assurance credit",
            url: "https://assurancecreditepargne.assurancevie.dev.desjardins.com/",
          },
          {
            idCallback: "2685f90d-3da5-4ba3-8180-65db0533e307",
            description: "SPA Souscription - DEV - FR",
            url: "https://assurancecreditepargne-adhesion.dev.desjardins.com/",
          },
          {
            idCallback: "0a16edf6-6d9a-4c72-a6e2-2b15b20391ab",
            description: "SPA Souscription - DEV - EN",
            url: "https:/creditsavingsinsurance-adhesion.dev.desjardins.com/",
          },
        ],
      },
      dev_integre: {
        ENV: "dev",
        authenfication: false,
        // @ts-ignore
        urlAssurabiliteBFF: "https://assurabilite-bff-jampp-ass-part-select-risque.apps.cfzcea.dev.desjardins.com",
        urlClickToCallDesktopFR:
          "https://rappel.desjardins.com/#/dcom-pleine-page",
        urlClickToCallDesktopEN:
          "https://rappel.desjardins.com/#/dcom-full-page",
        urlClickToCallMobileEN:
          "https://rappel.desjardins.com/#/mobile-services",
        urlClickToCallMobileFR:
          "https://rappel.desjardins.com/#/services-mobiles",
        urlSommaire: "https://accweb-martine.mouvdev.desjardins.com:443/identifiantunique/sso/redirect",
        consommateurCallBackInfos: [
          {
            idCallback: "700037b4-55cf-4828-a53a-2c0bad32ed7c",
            description: "SPA Souscription - UAT - FR",
            url: "https://octroi-spa-uat.apps.cfzcea.dev.desjardins.com/redirection/",
          },
          {
            idCallback: "ecc8725c-9844-4858-9c17-bf562bf60712",
            description: "SPA Souscription - UAT - EN",
            url: "https://octroi-spa-uat.apps.cfzcea.dev.desjardins.com/redirection/",
          },
          {
            idCallback: "7d2704a3-b75a-40cb-b0e7-c30d9a139139",
            description: "SPA Souscription - DEMO - FR",
            url: "https://octroi-spa-demo.apps.cfzcea.dev.desjardins.com/redirection/",
          },
          {
            idCallback: "ef76189c-4a13-4be2-85dc-a53b5cca13a2",
            description: "SPA Souscription - DEMO - EN",
            url: "https://octroi-spa-demo.apps.cfzcea.dev.desjardins.com/redirection/",
          },
          {
            idCallback: "92f8c7b8-e49c-4644-aae9-d20e1fb9dbf7",
            description: "SPA Souscription - CERTIF - FR",
            url: "https://octroi-spa-certif.apps.cfzcea.dev.desjardins.com/redirection/",
          },
          {
            idCallback: "5302fc8a-55b7-43ce-88a5-016b392262e0",
            description: "SPA Souscription - CERTIF - EN",
            url: "https://octroi-spa-certif.apps.cfzcea.dev.desjardins.com/redirection/",
          },
        ],
      },
      /** @type {Configurations} **/
      certification: {
        ENV: "certification",
        authenfication: true,
        urlAssurabiliteBFF: "https://api-azr-dev-d.desjardins.com/api/assurance-particuliers/bff-assurabilite/v1",
        urlClickToCallDesktopFR:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/dcom-pleine-page",
        urlClickToCallDesktopEN:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/dcom-full-page",
        urlClickToCallMobileEN:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/mobile-services",
        urlClickToCallMobileFR:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/services-mobiles",
        urlSommaire: "https://accweb-01-20494.mouvdev.desjardins.com:443/identifiantunique/sso/redirect",
        consommateurCallBackInfos: [
          {
            idCallback: "d1c8c1fc-6991-4f2c-b791-30d3cc459eb6",
            description: "RHN - DEV",
            url: "https://front-web-dev-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "33d2a6c7-f4de-4dc8-8cea-199c51abce68",
            description: "RHN - QA",
            url: "https://front-web-qa-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "6c472c2a-e8e0-4dee-9406-4677415ee9c8",
            description: "RHN - HA",
            url: "https://front-web-ha-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "056cc115-1e32-4493-8767-872515e072b5",
            description: "Assurance credit",
            url: "https://assurancecreditepargne-accp.assurancevie.desjardins.com/",
          },
          {
            idCallback: "f2d71a50-747a-4556-b232-3e8a9bc305ae",
            description: "Assurance credit",
            url: "https://assurancecreditepargne.assurancevie.dev.desjardins.com/",
          },
          {
            idCallback: "700037b4-55cf-4828-a53a-2c0bad32ed7c",
            description: "SPA Souscription - CERTIF - FR",
            url: "https://assurancecreditepargne-adhesion.certif.desjardins.com/",
          },
          {
            idCallback: "ecc8725c-9844-4858-9c17-bf562bf60712",
            description: "SPA Souscription - CERTIF - EN",
            url: "https://creditsavingsinsurance-adhesion.certi.desjardins.com/",
          },
        ],
      },
      /** @type {Configurations} **/
      integre: {
        ENV: "integre",
        authenfication: true,
        urlAssurabiliteBFF: "https://api-azr-dev-d.desjardins.com/api/assurance-particuliers/bff-assurabilite/v1",
        urlClickToCallDesktopFR: "https://rappel.desjardins.com/#/dcom-pleine-page",
        urlClickToCallDesktopEN: "https://rappel.desjardins.com/slkfgndflgmds#/dcom-full-page",
        urlClickToCallMobileEN: "https://rappel.desjardins.com/slkfgndflgmds#/mobile-services",
        urlClickToCallMobileFR: "https://rappel.desjardins.com/#/services-mobiles",
        urlSommaire: "https://accweb-01-20494.mouvdev.desjardins.com:443/identifiantunique/sso/redirect",
        consommateurCallBackInfos: [
          {
            idCallback: "d1c8c1fc-6991-4f2c-b791-30d3cc459eb6",
            description: "RHN - DEV",
            url: "https://front-web-dev-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "33d2a6c7-f4de-4dc8-8cea-199c51abce68",
            description: "RHN - QA",
            url: "https://front-web-qa-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "6c472c2a-e8e0-4dee-9406-4677415ee9c8",
            description: "RHN - HA",
            url: "https://front-web-ha-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "056cc115-1e32-4493-8767-872515e072b5",
            description: "Assurance credit",
            url: "https://assurancecreditepargne-accp.assurancevie.desjardins.com/",
          },
          {
            idCallback: "f2d71a50-747a-4556-b232-3e8a9bc305ae",
            description: "Assurance credit",
            url: "https://assurancecreditepargne.assurancevie.dev.desjardins.com/",
          },
          {
            idCallback: "700037b4-55cf-4828-a53a-2c0bad32ed7c",
            description: "SPA Souscription - CERTIF - FR",
            url: "https://octroi-spa-uat.apps.cfzcea.dev.desjardins.com/redirection/",
          },
          {
            idCallback: "ecc8725c-9844-4858-9c17-bf562bf60712",
            description: "SPA Souscription - CERTIF - EN",
            url: "https://octroi-spa-uat.apps.cfzcea.dev.desjardins.com/redirection/",
          },
        ],
      },
      /** @type {Configurations} **/
      preprod: {
        ENV: "preprod",
        authenfication: true,
        urlAssurabiliteBFF: "https://api-azr-pp.desjardins.com/api/assurance-particuliers/bff-assurabilite/v1",
        urlClickToCallDesktopFR:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/dcom-pleine-page",
        urlClickToCallDesktopEN:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/dcom-full-page",
        urlClickToCallMobileEN:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/mobile-services",
        urlClickToCallMobileFR:
          "https://projet-dcom-ng-click2call-dev.apps.cfzcea.dev.desjardins.com/#/services-mobiles",
        urlSommaire: "https://accweb-martine.mouvdev.desjardins.com:443/identifiantunique/sso/redirect",
        consommateurCallBackInfos: [
          {
            idCallback: "d1c8c1fc-6991-4f2c-b791-30d3cc459eb6",
            description: "RHN - DEV",
            url: "https://front-web-dev-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "33d2a6c7-f4de-4dc8-8cea-199c51abce68",
            description: "RHN - QA",
            url: "https://front-web-qa-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "6c472c2a-e8e0-4dee-9406-4677415ee9c8",
            description: "RHN - HA",
            url: "https://front-web-ha-parcours-habitation.cfzcea.mouvdev.desjardins.com/",
          },
          {
            idCallback: "056cc115-1e32-4493-8767-872515e072b5",
            description: "Assurance credit",
            url: "https://assurancecreditepargne-accp.assurancevie.desjardins.com/",
          },
          {
            idCallback: "700037b4-55cf-4828-a53a-2c0bad32ed7c",
            description: "SPA Souscription - CERTIF - FR",
            url: "https://assurancecreditepargne-adhesion.certif.desjardins.com/",
          },
          {
            idCallback: "ecc8725c-9844-4858-9c17-bf562bf60712",
            description: "SPA Souscription - CERTIF - EN",
            url: "https://creditsavingsinsurance-adhesion.certi.desjardins.com/",
          },
        ],
      },
      /** @type {Configurations} **/
      prod: {
        ENV: "prod",
        authenfication: true,
        urlAssurabiliteBFF: "https://api-azr.desjardins.com/api/assurance-particuliers/bff-assurabilite/v1",
        urlClickToCallDesktopFR: "https://rappel.desjardins.com/#/dcom-pleine-page",
        urlClickToCallDesktopEN: "https://rappel.desjardins.com/slkfgndflgmds#/dcom-full-page",
        urlClickToCallMobileEN: "https://rappel.desjardins.com/slkfgndflgmds#/mobile-services",
        urlClickToCallMobileFR: "https://rappel.desjardins.com/#/services-mobiles",
        urlSommaire: "https://accweb.mouv.desjardins.com/identifiantunique/sso/redirect",
        consommateurCallBackInfos: [
          {
            idCallback: "e8a5ebf4-6507-47dd-9386-020c2901b108",
            description: "RHN - PROD",
            url: "https://renouvellement-hypothecaire.accesd.mouv.desjardins.com/",
          },
          {
            idCallback: "02f7e7a7-4cca-4a0f-bbea-ba851bfafa09",
            description: "Assurance credit",
            url: "https://assurancecreditepargne.assurancevie.desjardins.com/",
          },
          {
            idCallback: "23b2640a-ae39-4d3d-9bf7-8835a095e327",
            description: "SPA Souscription - PROD - FR",
            url: "https://assurancecreditepargne-adhesion.desjardins.com/",
          },
          {
            idCallback: "0642148b-b359-48a5-ab8e-1e8ed4a9a256",
            description: "SPA Souscription - PROD - EN",
            url: "https://creditsavingsinsurance-adhesion.desjardins.com/",
          },
        ],
      },
    };
  }
}
const config = new Configuration();

export default config;
