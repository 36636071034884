import i18n from "../../resources/I18n";
import { DsdModale } from "../DSD/DsdModale";
import { Cybermetries } from "../../util/CybermetrieAPRHN";
import Configuration from "../../Configuration";
import { useCybermetrieContext } from "../utils/CybermetrieAPRHNContext";
import { useEffect, useRef } from "react";
import { CybermetrieParams } from "../../models/CybermetrieParamsAPRHN";
import { INavigationParcours } from "../../util/Navigation";
import ServiceAssurabiliteBFF from "../utils/ServiceAssurabiliteBFF";

interface IModaleEnregistrer {
  modaleOuvert: boolean;
  onClickModaleEnregistrerFermer: Function;
  cookieParcoursAssurance: INavigationParcours;
}

export default function ModaleEnregistrer(props: Readonly<IModaleEnregistrer>) {
  const serviceAssurabiliteBFF = new ServiceAssurabiliteBFF();

  let cybermetrieParams = useCybermetrieContext();
  const ancienneValeurCybermetrieParams = useRef(new CybermetrieParams());

  useEffect(() => {
    ancienneValeurCybermetrieParams.current = cybermetrieParams;
  }, [cybermetrieParams]);

  const onClickBoutonEnregistrer = () => {
    serviceAssurabiliteBFF.appelEnregistrerQuitter(
      props.cookieParcoursAssurance.idSouscription,
      props.cookieParcoursAssurance.idAssure
    );
    Cybermetries.EnvoieEnregistrerEvent(ancienneValeurCybermetrieParams.current);
    window.location.href = Configuration.obtenirConfigurations().urlSommaire;
  };

  return (
    <DsdModale
      id="dsd-modal-enregistrer"
      dataTestId="dsd-modal-enregistrer"
      show={props.modaleOuvert}
      buttonPrimaryLabel={i18n.t("assurabilite:bouton-principal-modale-enregistrer-et-quitter")}
      buttonSecondaryLabel={i18n.t("assurabilite:bouton-secondaire-modale-enregistrer-et-quitter")}
      dsdModalSecondaryClick={props.onClickModaleEnregistrerFermer}
      dsdModalPrimaryClick={onClickBoutonEnregistrer}
      spanSlotTitle={i18n.t("assurabilite:titre-modale-enregistrer-et-quitter")}
    >
      <p>{i18n.t("assurabilite:texte-modale-enregistrer-et-quitter")}</p>
    </DsdModale>
  );
}
